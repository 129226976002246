import { enqueueSnackbar } from 'notistack';

export const generateUniqueKey = () => btoa(Math.random()).substring(0, 12);

export function debouncePromise(fn, delay = 500) {
	let timer;

	return (...args) => {
		if (timer) clearTimeout(timer);

		return new Promise((resolve, reject) => {
			timer = setTimeout(async () => {
				try {
					const result = await fn(...args);

					resolve(result);
				} catch (error) {
					reject(error);
				}
			}, delay);
		});
	};
}

export function arrayDiff(biggerArray, smallerArray) {
	return biggerArray.filter((item) => !smallerArray.includes(item));
}

export const alertUser = (message, variant = 'info', autoHideDuration = 5 * 1000) => {
	enqueueSnackbar(message, {
		variant,
		autoHideDuration,
		preventDuplicate: true,
	});
};
