export let STORES;

export const setSharedStores = (stores) => {
	STORES = stores;
};

export const resetStores = (ignoredStores = []) => {
	Object.keys(STORES).forEach((storeKey) => {
		const store = STORES[storeKey];

		if (!ignoredStores.includes(storeKey) && store.reset) {
			store.reset();
		}
	});
};
